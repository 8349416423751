import React from 'react'
import * as style from '../styles/theme/zoomablePhoto.module.scss'

const ZoomablePhoto = ({ src, title, width = 200 }) => {
  const [zoomed, setZoomed] = React.useState(false)
  return (
    <div>
      <img
        className="rounded shadow"
        src={ src }
        width={ zoomed ? 'auto' : width }
        style={{ cursor: zoomed ? 'zoom-out' : 'zoom-in', margin: 10 }}
        onClick={ () => setZoomed(!zoomed) }
        alt={ title }
      />
      <em className={ style.title }>{ title }</em>
    </div>
  )
}

export default ZoomablePhoto

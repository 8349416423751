import React from 'react'
import { Link } from 'gatsby'

import SEO from '../components/seo'
import Layout from '../components/layout'
import ZoomablePhoto from '../components/ZoomablePhoto'
import * as style from '../styles/theme/gallery.module.scss'

const ShepradorPuppies = () => (
  <Layout>
    <SEO title="Sheprador Puppies" />
    <h1>Sheprador Puppies</h1>
    <p>We currently have a litter of absolutely gorgeous Sheprador puppies (75% GSD / 25% Labrador Retriever) available for adoption.</p>
    <p>The dam is Inka, our lovely long-coat dark bi-colour pure GSD girl (offspring of our Eva and Thor) and the sire is Teddy,
      our handsome white long-coat Sheprador (50/50 GSD/Labrador). <Link to="contact">Contact us</Link> to enquire
      about adopting a puppy.</p>
    <h2>Parents</h2>
    <div className={ style.section }>
      <ZoomablePhoto src="/teddy1.jpg" title="Teddy (sire)" />
      <ZoomablePhoto src="/teddy2.jpg" title="Teddy (sire)" />
      <ZoomablePhoto src="/inka.jpg" title="Inka (dam)" />
    </div>
    <h2>Puppies</h2>
    <div className={ style.section }>
      <ZoomablePhoto src="/sheprador-pics-04-2021/1.jpg" title="" />
      <ZoomablePhoto src="/sheprador-pics-04-2021/2.jpg" title="" />
      <ZoomablePhoto src="/sheprador-pics-04-2021/3.jpg" title="" />
      <ZoomablePhoto src="/sheprador-pics-04-2021/4.jpg" title="" />
      <ZoomablePhoto src="/sheprador-pics-04-2021/5.jpg" title="" />
      <ZoomablePhoto src="/sheprador-pics-04-2021/6.jpg" title="" />
      <ZoomablePhoto src="/sheprador-pics-04-2021/7.jpg" title="" />
      <ZoomablePhoto src="/sheprador-pics-04-2021/8.jpg" title="" />
      <ZoomablePhoto src="/sheprador-pics-04-2021/9.jpg" title="" />
      <ZoomablePhoto src="/sheprador-pics-04-2021/10.jpg" title="" />
      <ZoomablePhoto src="/sheprador-pics-04-2021/11.jpg" title="" />
      <ZoomablePhoto src="/sheprador-pics-04-2021/12.jpg" title="" />
      <ZoomablePhoto src="/sheprador-pics-04-2021/13.jpg" title="" />
    </div>

  </Layout>
)

export default ShepradorPuppies
